@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@200;400;500;600;700;800&display=swap");

* {
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  margin: 0;
  font-family: "Lexend", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

main {
  min-height: 100vh;
  width: 100%;
  background-color: #fbf6f3;
}
a {
  text-decoration: none;
  color: "inherit";
}

::-webkit-scrollbar {
  width: 8px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0 !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ff6d5f !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #ff6d5f !important;
}
